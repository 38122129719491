<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">Empleados</h2>
        </v-col>

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <!-- Clients list cards -->
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <!-- FILTROS -->
              <v-row>
                <!-- Filtro por DNI -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    label="DNI"
                    prepend-icon="mdi-account-card-details-outline"
                    v-model="filter.dni"
                  ></v-text-field>
                </v-col>

                <!-- Filtro por nombre y/o apellidos -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    label="Display name"
                    prepend-icon="mdi-account-circle-outline"
                    v-model="filter.displayName"
                  ></v-text-field>
                </v-col>

                <!-- Filtro por email -->
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    label="E-mail address"
                    prepend-icon="mdi-email"
                    v-model="filter.email"
                  ></v-text-field>
                </v-col>

                <!-- Buttons -->
                <v-col cols="12" class="text-right">
                  <v-btn @click="reset" class="ma-2">Limpiar filtros</v-btn>
                  <v-btn color="primary" @click="search(1)" class="ma-2"
                    >Buscar</v-btn
                  >
                </v-col>
              </v-row>
              <!-- FILTROS -->

              <!-- Clients table -->
              <v-card flat tile class="border-bottom">
                <v-card-text>
                  <v-row no-gutters class="grey--text text--darken-4">
                    <v-col
                      cols="8"
                      sm="6"
                      md="4"
                      class="subtitle-1 text-truncate"
                      >Nombre completo</v-col
                    >
                    <v-col
                      cols="4"
                      sm="3"
                      md="2"
                      class="subtitle-1 text-truncate"
                      >DNI</v-col
                    >
                    <v-col md="4" class="subtitle-1 d-none d-md-flex"
                      >E-mail</v-col
                    >

                    <v-col sm="3" md="2" class="subtitle-1 d-none d-sm-flex"
                      >Grupo</v-col
                    >
                    <!-- <v-col class="subtitle-1 text-truncate d-none d-md-flex">Detalles</v-col> -->
                  </v-row>
                </v-card-text>
              </v-card>

              <v-alert
                :value="employees.length == 0"
                border="left"
                colored-border
                type="info"
                elevation="2"
                class="mt-4"
                >No hay ningún empleado para los criterios de búsqueda.</v-alert
              >

              <eximyr-employee
                v-for="(employee, index) in employees"
                :key="'employee_' + employee.id"
                :employee="employee"
                :last="employees.length - 1 == index"
                @click="edit(employee)"
              ></eximyr-employee>
              <!-- Employee table -->
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Clients list cards -->

        <!-- Start pagination -->
        <v-col cols="12">
          <div class="text-right my-4">
            <v-pagination
              v-model="page"
              :length="last_page"
              :total-visible="7"
              @input="search"
            ></v-pagination>
          </div>
        </v-col>
        <!-- End pagination -->
      </v-row>
    </v-container>

    <!-- Create fab button -->
    <v-fab-transition>
      <v-btn
        color="red"
        fab
        dark
        large
        bottom
        right
        ripple
        fixed
        @click="create"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- Create fab button -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';

import EximyrLoading from '../components/EximyrLoading';
import EximyrEmployee from './components/EximyrEmployee';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EximyrEmployee,
  },

  data: () => ({
    page: null,

    filter: {
      dni: '',
      displayName: '',
      email: '',
    },
  }),

  computed: {
    ...mapGetters('employees', {
      employees: 'employees',
      last_page: 'last_page',
      loading: 'loading',
    }),
  },

  watch: {
    $route() {
      this.load();
    },
  },

  created() {
    // Set the current page if there is one set
    this.page = this.currentPage();

    // Set all filter variables from URL
    // Asign filter variables
    this.filter.dni = this.currentFilterDni();
    this.filter.displayName = this.currentFilterDisplayName();
    this.filter.email = this.currentFilterEmail();

    // Now load all employees
    this.load();
  },

  methods: {
    currentPage() {
      if (isNaN(this.$route.query.page)) {
        return 1;
      } else {
        return parseInt(this.$route.query.page);
      }
    },

    load() {
      // Create the payload
      const payload = {
        restaurant: this.$route.params.restaurant,
        filter: this.filter,
        page: this.page,
      };

      this.$store
        .dispatch('employees/load', payload)
        .then(results => {
          // Clear all errors
          this.clearErrors();

          if (this.page > results.data.last_page) {
            // Reload
            this.search(results.data.last_page);
          }
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    search(page = null) {
      // Filter the orders
      this.$router
        .push({
          name: 'employees',
          params: {
            restaurant: this.$route.params.restaurant,
          },
          query: this.query(page),
        })
        // We need to ignore the error (same route)
        .catch(() => {});
    },

    query(page = null) {
      if (page) this.page = page;

      let query = {
        page: this.page,
      };

      // Check all filters
      if (this.filter.displayName != '') {
        query.displayName = this.filter.displayName;
      }

      if (this.filter.dni.trim() != '') {
        query.dni = this.filter.dni;
      }

      if (this.filter.email.trim() != '') {
        query.email = this.filter.email;
      }

      // Check all filters - use DNI displayName and email
      // if (this.filter.tables.length > 0)
      //   query.tables = this.filter.tables.join(',');
      // if (this.filter.status !== null) query.status = this.filter.status;

      return query;
    },

    reset() {
      this.filter.displayName = '';
      this.filter.dni = '';
      this.filter.email = '';
    },

    // Filter methods
    currentFilterDni() {
      let dni = this.$route.query.dni;

      if (dni === undefined) {
        return '';
      }

      return dni.toString();
    },

    currentFilterDisplayName() {
      let displayName = this.$route.query.displayName;

      if (displayName === undefined) {
        return '';
      }

      return displayName.toString();
    },

    currentFilterEmail() {
      let email = this.$route.query.email;

      if (email === undefined) {
        return '';
      }

      return email.toString();
    },

    /**
     * Create new waiter
     */
    create() {
      this.$router.push({
        name: 'employees.create',
      });
    },

    /**
     * Edit new waiter
     */
    edit(employee) {
      this.$store
        .dispatch('employees/clear')
        .then(() => {
          this.$router.push({
            name: 'employees.edit',
            params: { employee: employee.id },
          });
        })
        .catch(error => {
          this.storeError(error);
        });
    },
  },
};
</script>

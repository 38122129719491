<template>
  <v-card
    flat
    tile
    ripple
    :class="last ? '' : 'border-bottom'"
    @click="$emit('click')"
  >
    <v-card-text>
      <v-row no-gutters align="center">
        <v-col
          cols="8"
          sm="6"
          md="4"
          class="text-truncate grey--text text--darken-3"
        >
          <v-icon class="pr-2">mdi-account-circle-outline</v-icon>
          {{ name }}
        </v-col>

        <v-col cols="4" sm="3" md="2" class="align-end subtitle-2">
          <v-icon class="pr-2">mdi-account-card-details-outline</v-icon>
          {{ employee.dni || 'No informado' }}
        </v-col>

        <v-col md="4" class="subtitle-2 d-none d-md-flex text-truncate">{{
          employee.email
        }}</v-col>

        <v-col sm="3" md="2" class="subtitle-2 text-truncate d-none d-sm-flex">
          <span class="mr-5">
            <!-- <v-icon class="pr-2">mdi-clock-check-outline</v-icon> -->
            {{ employee.group.name }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    last: Boolean,
    employee: Object,
  },

  computed: {
    name() {
      return (
        (this.employee.first_name || '') +
        ' ' +
        (this.employee.first_surname || '') +
        ' ' +
        (this.employee.second_surname || '')
      );
    },
    // authorized() {
    //   return (
    //     this.client.authorized_by != null && this.client.authorized_at != null
    //   );
    // },
    // createdTime() {
    //   return moment(this.client.created_at).format('HH:mm:ss');
    // },
    // authorizedTime() {
    //   if (this.client.authorized_at) {
    //     return moment(this.client.authorized_at).format('HH:mm:ss');
    //   }
    //   return '';
    // }
  },

  methods: {},
};
</script>

<style></style>

<template>
  <v-main>
    <v-container fluid>
      <v-row align="center">
        <eximyr-breadcrumb :breadcrumbs="breadcrumbs"></eximyr-breadcrumb>

        <v-col cols="12">
          <h2 v-if="create" class="display-1 font-weight-light pl-5">
            Crear nuevo empleado
          </h2>
          <h2 v-else class="display-1 font-weight-light pl-5">
            Editar empleado
          </h2>
        </v-col>

        <!-- Errors and alerts -->
        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="flex-row-reverse">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Nombre del empleado"
                      :counter="150"
                      prepend-icon="mdi-account-circle-outline"
                      required
                      v-model="first_name"
                      :rules="rules.first_name"
                      :error-messages="backend_errors.first_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Primer apellido"
                      :counter="150"
                      prepend-icon="mdi-account-circle-outline"
                      required
                      v-model="first_surname"
                      :rules="rules.first_surname"
                      :error-messages="backend_errors.first_surname"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Segundo apellido"
                      :counter="150"
                      prepend-icon="mdi-account-circle-outline"
                      required
                      v-model="second_surname"
                      :rules="rules.second_surname"
                      :error-messages="backend_errors.second_surname"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Documento identidad"
                      :counter="9"
                      prepend-icon="mdi-account-card-details-outline"
                      v-model="dni"
                      :error-messages="backend_errors.dni"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      label="Correo electrónico"
                      :counter="255"
                      prepend-icon="mdi-email-outline"
                      required
                      v-model="email"
                      :rules="rules.email"
                      :error-messages="backend_errors.email"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <v-select
                      v-model="group_id"
                      :items="groups"
                      label="Grupo empleado"
                      item-value="id"
                      item-text="name"
                      prepend-icon="mdi-account-group-outline"
                      :rules="rules.group_id"
                      :error-messages="backend_errors.group_id"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn class="mr-2" @click="cancel">Cancelar</v-btn>
                    <v-btn color="primary" @click="save">Guardar</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import goTo from 'vuetify/lib/services/goto';

import { mapGetters } from 'vuex';

import { required } from '@/validators/required';
import { length } from '@/validators/length';
import { email } from '@/validators/email';

// Language loader mixin
import errors from '../mixins/errors';

import EximyrBreadcrumb from '../components/EximyrBreadcrumb';
import EximyrLoading from '../components/EximyrLoading';

export default {
  components: {
    EximyrBreadcrumb,
    EximyrLoading,
  },

  mixins: [errors],

  data: () => ({
    create: false,

    restaurant_uuid: 0,

    groups: [
      {
        id: 3,
        name: 'Owner',
      },
      {
        id: 4,
        name: 'Waiter',
      },
    ],

    // All languages are needed so we can have reactivity
    id: 0,
    first_name: '',
    first_surname: '',
    second_surname: '',
    dni: '',
    email: '',
    group_id: '',

    backend_employee: {
      id: null,
      first_name: null,
      first_surname: null,
      second_surname: null,
      dni: null,
      email: null,
      group_id: null,
    },

    backend_errors: {
      first_name: [],
      first_surname: [],
      second_surname: [],
      dni: [],
      email: [],
      group_id: [],
    },
  }),

  computed: {
    ...mapGetters('employees', {
      loading: 'loading',
    }),

    fullName() {
      return (
        (this.first_name || '') +
        ' ' +
        (this.first_surname || '') +
        ' ' +
        (this.second_surname || '')
      );
    },

    breadcrumbs() {
      const breadcrumbs = [
        {
          text: this.$t('navigation.employees'),
          disabled: false,
          exact: true,
          to: { name: 'employees' },
        },
      ];

      if (this.create) {
        breadcrumbs.push({
          text: this.$t('employees.breadcrumb.create'),
          disabled: true,
          exact: false,
        });
      } else {
        breadcrumbs.push({
          text: this.$t('employees.breadcrumb.edit', {
            name: this.fullName,
          }),
          disabled: true,
          exact: false,
        });
      }

      return breadcrumbs;
    },

    // Validation errors
    rules() {
      return {
        first_name: [required(this), length(this, 150)],
        first_surname: [required(this), length(this, 150)],
        second_surname: [length(this, 150)],
        // dni: [required(this), dni(this)],
        email: [required(this), length(this, 255), email(this)],
        group_id: [required(this)],
      };
    },
  },

  created() {
    // Check if we are in edit mode or in create mode
    if (!this.$route.params.employee) {
      this.create = true;

      return;
    }

    // We must load the allergen now
    this.$store
      .dispatch('employees/loadEmployee', {
        restaurant: this.$route.params.restaurant,
        employee: this.$route.params.employee,
      })
      .then(response => {
        this.first_name = response.data.first_name;
        this.first_surname = response.data.first_surname;
        this.second_surname = response.data.second_surname;
        this.dni = response.data.dni;
        this.email = response.data.email;
        this.group_id = response.data.group.id;
        this.id = this.$route.params.employee;

        this.backend_employee.first_name = response.data.first_name;
        this.backend_employee.first_surname = response.data.first_surname;
        this.backend_employee.second_surname = response.data.second_surname;
        this.backend_employee.dni = response.data.dni;
        this.backend_employee.email = response.data.email;
        this.backend_employee.group_id = response.data.group.id;
        this.backend_employee.id = this.$route.params.employee;
      })
      .catch(error => {
        this.storeError(error);
      });
  },

  methods: {
    cancel() {
      this.$router.push({
        name: 'employees',
        params: {
          restaurant: this.$route.params.restaurant,
        },
        query: {
          page: this.create ? 1 : this.current_page,
        },
      });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let employee = {
        restaurant_uuid: this.$route.params.restaurant,
      };

      if (this.first_name != this.backend_employee.first_name)
        employee.first_name = this.first_name;
      if (this.first_surname != this.backend_employee.first_surname)
        employee.first_surname = this.first_surname;
      if (this.second_surname != this.backend_employee.second_surname)
        employee.second_surname = this.second_surname;
      if (this.email != this.backend_employee.email)
        employee.email = this.email;
      if (this.group_id != this.backend_employee.group_id)
        employee.group_id = this.group_id;

      employee.dni = this.dni;

      let action = '';
      if (this.create) {
        // Create new employee / user
        action = 'employees/create';
      } else {
        // Update the employee - set the employee's id
        employee.id = this.id;
        action = 'employees/update';
      }

      // Update / create the employee
      this.$store
        .dispatch(action, employee)
        .then(() => {
          // Clear all errors
          this.clearErrors();

          // Once created get back to the list
          this.$router.push({
            name: 'employees',
            params: {
              restaurant: this.$route.params.restaurant,
            },
            query: {
              page: this.current_page,
            },
          });
        })
        .catch(error => {
          this.storeError(error);
          goTo(0);

          // Check all backend errors
          let errors = error.response.data.errors;
          this.backend_errors.first_name = errors.first_name || [];
          this.backend_errors.first_surname = errors.first_surname || [];
          this.backend_errors.second_surname = errors.second_surname || [];
          this.backend_errors.dni = errors.dni || [];
          this.backend_errors.email = errors.email || [];
          this.backend_errors.group_id = errors.group_id || [];

          // if (error.response.data.errors.email) {
          // }
        });
    },
  },
};
</script>
